import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../components/image';
import { DiagnoseBannerWrap } from './banner.stc';

const DiagnoseBanner = () => {
  const diagnoseBannerData = useStaticQuery(graphql`
    query DiagnoseBannerQuery {
      diagnoseJson(id: { eq: "diagnose-page-banner" }) {
        banner {
          childImageSharp {
            fluid(
              maxWidth: 1260
              maxHeight: 585
              quality: 100
              srcSetBreakpoints: 6
            ) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);
  const bannerImg =
    diagnoseBannerData.diagnoseJson.banner.childImageSharp.fluid;
  return (
    <DiagnoseBannerWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Image fluid={bannerImg} alt="Diagnoza i terapia" />
          </Col>
        </Row>
      </Container>
    </DiagnoseBannerWrap>
  );
};

export default DiagnoseBanner;
