import React from 'react';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import DiagnoseTitle from '../containers/diagnose/title';
import DiagnoseBanner from '../containers/diagnose/banner';
import DiagnoseContent from '../containers/diagnose/content';
import InstagramWrap from '../containers/global/instagram';
import SEO from '../components/seo';

const DiagnosePage = () => (
  <Layout>
    <SEO title="Diagnoza i terapia" />
    <Header />
    <div className="main-content">
      <DiagnoseTitle />
      <DiagnoseBanner />
      <DiagnoseContent />
      <InstagramWrap />
    </div>
    <Footer />
  </Layout>
);

export default DiagnosePage;
