import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from '../../components/shared/heading';
import { TitleWrap } from './title.stc';

const DiagnoseTitleArea = ({ titleCSS, taglineCSS }) => {
  const DiagnoseTitleData = useStaticQuery(graphql`
    query DiagnoseTitleQuery {
      diagnoseJson(id: { eq: "diagnose-page-header" }) {
        title
        tagline
      }
    }
  `);

  const { title, tagline } = DiagnoseTitleData.diagnoseJson;

  return (
    <TitleWrap>
      <Container>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xl={{ size: 7, offset: 2 }}>
            {title && <Heading {...titleCSS}>{title}</Heading>}
            {tagline && <Heading {...taglineCSS}>{tagline}</Heading>}
          </Col>
        </Row>
      </Container>
    </TitleWrap>
  );
};

DiagnoseTitleArea.defaultProps = {
  titleCSS: {
    as: 'h5',
    fontSize: [1],
    textTransform: 'capitalize',
    mb: '17px'
  },
  taglineCSS: {
    color: 'secondaryColor',
    fontSize: [4, 5],
    fontWeight: 'bold',
    lineHeight: [1.4, 1.375]
  }
};

export default DiagnoseTitleArea;
