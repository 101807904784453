import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from '../../components/shared/heading';
import Text from '../../components/shared/text';
import {
  DiagnoseContentWrap,
  DiagnoseContentTop,
  DiagnoseContentBottom
} from './content.stc';

const DiagnoseContentArea = props => {
  const diagnoseData = useStaticQuery(graphql`
    query DiagnoseContentQuery {
      diagnoseJson(id: { eq: "diagnose-page-content" }) {
        top_content {
          content {
            text1
            text2
            text3
          }
        }
        bottom_content {
          heading
          content {
            text1
            text2
            text3
          }
        }
      }
    }
  `);

  const { top_content, bottom_content } = diagnoseData.diagnoseJson;

  const { topHeadingCSS, textCSS, bottomHeadingCSS } = props;

  return (
    <DiagnoseContentWrap>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} xl={8}>
            {top_content && (
              <DiagnoseContentTop>
                {top_content.heading && (
                  <Heading {...topHeadingCSS}>{top_content.heading}</Heading>
                )}
                {top_content.content.text1 && (
                  <Text {...textCSS}>{top_content.content.text1}</Text>
                )}
                {top_content.content.text2 && (
                  <Text {...textCSS}>{top_content.content.text2}</Text>
                )}
                {top_content.content.text3 && (
                  <Text {...textCSS}>{top_content.content.text3}</Text>
                )}
                {top_content.content.text4 && (
                  <Text {...textCSS}>{top_content.content.text4}</Text>
                )}
                {top_content.content.text5 && (
                  <Text {...textCSS}>{top_content.content.text5}</Text>
                )}
                {top_content.content.text6 && (
                  <Text {...textCSS}>{top_content.content.text6}</Text>
                )}
                {top_content.content.text7 && (
                  <Text {...textCSS}>{top_content.content.text7}</Text>
                )}
              </DiagnoseContentTop>
            )}

            {bottom_content && (
              <DiagnoseContentBottom>
                {bottom_content.heading && (
                  <Heading {...bottomHeadingCSS}>
                    {bottom_content.heading}
                  </Heading>
                )}
                {bottom_content.content.text1 && (
                  <Text {...textCSS}>{bottom_content.content.text1}</Text>
                )}
                {bottom_content.content.text2 && (
                  <Text {...textCSS}>{bottom_content.content.text2}</Text>
                )}
                {bottom_content.content.text3 && (
                  <Text {...textCSS}>{bottom_content.content.text3}</Text>
                )}
              </DiagnoseContentBottom>
            )}
          </Col>
        </Row>
      </Container>
    </DiagnoseContentWrap>
  );
};

DiagnoseContentArea.defaultProps = {
  topHeadingCSS: {
    as: 'h4',
    color: 'secondaryColor',
    lineHeight: 1.375,
    mb: '17px'
  },
  textCSS: {
    mb: '26px'
  },
  bottomHeadingCSS: {
    as: 'h5',
    mb: '24px'
  }
};

export default DiagnoseContentArea;
