import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { device } from '../../theme';

export const DiagnoseContentWrap = styled.section`
  padding-bottom: 56px;
  ${device.small} {
    padding-bottom: 76px;
  }
  ${device.medium} {
    padding-bottom: 96px;
  }
`;

export const DiagnoseContentTop = styled.div`
  margin-bottom: 38px;
`;

export const DiagnoseContentBottom = styled.div`
  border-top: 1px solid ${themeGet('colors.borderColor')};
  padding-top: 42px;
  margin-top: 35px;
`;
